import React from 'react';
import { css } from '@emotion/css';
import Slider from 'react-slick';

// react-slickのスタイルシート
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// ポートフォリオセクションのスタイル
const portfolioStyle = css`
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  margin: 80px auto 20px;
  margin: 80px auto 20px; // センタリングと余白の調整
  width: 80%;
  border-radius: 10px;
`;

// 動画コンポーネントのスタイル
const videoStyle = css`
  margin: 20px;
  border: none;
  width: 75%;
  height: 203px;
  border-radius: 50px;
`;

// カルーセルのカスタムスタイル
const customSliderStyle = css`
  .slick-prev:before, .slick-next:before {
    color: gray; // 移動ボタンの色をグレーに設定
  }
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true
};

function Portfolio() {
  // 動画と説明文の配列を定義
  const videoData = [
    {
      url: "https://www.youtube.com/embed/p4ioAngaBYM",
      title: "不可幸力",
      description: "とてもシンプルなMIXの例です\n"
    },
    {
      url: "https://www.youtube.com/embed/JR2l9cd44PE",
      title: "独唱",
      description: "シンプルなMIXその2\n"
    },
    {
      url: "https://www.youtube.com/embed/eCh77dpkRGQ",
      title: "絶頂賛歌",
      description: "加工感が少しあるMIXです\n"
    },
    // 他の動画データ
  ];

  return (
    <div className={portfolioStyle}>
      <h2>実績</h2>
      <Slider {...sliderSettings} className={customSliderStyle}>
        {videoData.map((data, index) => (
          <div key={index}>
            <iframe
              className={videoStyle}
              src={data.url}
              title={`YouTube video ${index}`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
            <h3>{data.title}</h3>
            <p>{data.description.split('\n').map((line, key) => (
              <span key={key}>{line}<br /></span>
            ))}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Portfolio;
