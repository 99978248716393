import React, { useState } from 'react';
import { css, keyframes } from '@emotion/css';

// タブのコンテナのスタイル
const tabContainerStyle = css`
  display: flex;
  justify-content: center;
`;

// タブのスタイル
const tabStyle = css`
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  margin-right: 5px;
  background-color: #f9f9f9;
  border-radius: 5px;

  &.active {
    background-color: white;
    border-bottom: 2px solid #4caf50;
    font-weight: bold;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// タブコンテンツのスタイル
const tabContentStyle = css`
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
`;

// 個別のタブコンテンツのスタイル
const tabContentItemStyle = css`
  animation: ${fadeIn} 0.5s ease-in-out;
`;

// Pricingコンテンツのスタイル
const pricingContentStyle = css`
  background-color: white;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top:80px;
  margin: 80px auto 20px; // センタリングと余白の調整
  width: 80%;
  border-radius: 10px;
`;

function Pricing() {
  const [activeTab, setActiveTab] = useState('basic');

  return (
    <div className={pricingContentStyle}>
      <h2 style={{ textAlign: 'center' }}>料金など</h2>
      <div className={tabContainerStyle}>
        <div
          className={`${tabStyle} ${activeTab === 'basic' ? 'active' : ''}`}
          onClick={() => setActiveTab('basic')}
        >
          ワンコーラス
        </div>
        <div
          className={`${tabStyle} ${activeTab === 'premium' ? 'active' : ''}`}
          onClick={() => setActiveTab('premium')}
        >
          FULL
        </div>
      </div>
      <div className={tabContentStyle}>
        {activeTab === 'basic' && (
          <div className={tabContentItemStyle}>
            <table>
              <tbody>
                <tr><td>料金</td><td>：0円</td></tr>
                <tr><td>納期の目安</td><td>：即日~1週間</td></tr>
              </tbody>
            </table>
          </div>
        )}
        {activeTab === 'premium' && (
          <div className={tabContentItemStyle}>
            <table>
              <tbody>
                <tr><td>料金</td><td>：0円</td></tr>
                <tr><td>納期の目安</td><td>：3日~1週間</td></tr>
              </tbody>
            </table>
          </div>
        )}
        {/* 他のタブの内容も同様に追加 */}
      </div>
      <div style={{ marginTop: '30px', textAlign: 'center' }}>
        <h3 style={{ marginBottom: '15px' }}>注意点</h3>
        <p>下記の事情により依頼をお断りするかも</p>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          <li>プライベートが忙しい</li>
          <li>MIXに手間がかかる(カットアップ等)</li>
          <li>ハモリ生成・ピッチ補正はもちろんOK！</li>
        </ul>
        <h3 style={{ marginTop: '30px', marginBottom: '15px' }}>依頼・提出音源について</h3>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          <li>録音環境は問いません</li>
          <li>わからないことは気軽にDMください</li>
          <li>動画投稿時、クレジットを明記してください。<br/>
            例...Mix:kokotro(twitterURL)</li>
        </ul>
      </div>
    </div>
  );
}

export default Pricing;
