import React from 'react';
import { css } from '@emotion/css';

// ナビゲーションバーのスタイル
const navbarStyle = css`
  background-color: #333;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// リンクのスタイル
const linkStyle = css`
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  display: block;

  &:hover {
    background-color: #444;
  }
`;

function Navbar() {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };
  
    return (
        <nav className={navbarStyle}>
          <div className={linkStyle} onClick={() => scrollToSection('pricing')}>料金など</div>
          <div className={linkStyle} onClick={() => scrollToSection('portfolio')}>実績</div>
          <div className={linkStyle} onClick={() => scrollToSection('contact')}>お問い合わせ</div>
        </nav>
      );
  }
  
  export default Navbar;