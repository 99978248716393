import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Aboutme from './components/Aboutme/Aboutme';
import Pricing from './components/Pricing/Pricing';
import Portfolio from './components/Portfolio/Portfolio';
import Contact from './components/Contact/Contact';
import { css } from '@emotion/css';
import './style.css';

const appStyle = css`
  max-width: 600px; // スマートフォン用の最大幅
  margin: auto;
  padding: 10px;
`;

function App() {
  return (
    <div className={appStyle}>
    <Navbar />
      <section id="aboutme">
       <Aboutme />
      </section>
      <section id="pricing">
        <Pricing />
      </section>
      <section id="portfolio">
        <Portfolio />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}

export default App;
