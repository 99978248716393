import React from 'react';
import { css } from '@emotion/css';

// Contactコンテンツのスタイル
const contactStyle = css`
  background-color: #f9f9f9; // 背景色をやわらかく
  padding: 40px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05); // 影を柔らかく
  margin: 60px auto 20px; // センタリングと余白の調整
  width: 50%; // 幅を調整
  border-radius: 10px; // 角を丸く
`;

// リンクスタイル
const linkStyle = css`
  color: #1DA1F2;
  text-decoration: none;
  font-size: 20px;
  &:hover {
    text-decoration: underline;
  }
  padding: 10px; // リンクに余白を追加
  border-radius: 5px; // 角を丸く
  transition: background-color 0.3s; // 背景色の変化をスムーズに
  &:hover {
    background-color: #e8f4fd; // ホバー時の背景色変化
  }
`;

function Contact() {
  return (
    <div className={contactStyle}>
      <h2>お問い合わせ</h2>
      <p>以下のリンクからお気軽にDMください</p>
      <a
        href="https://twitter.com/kokotro_nico"
        className={linkStyle}
        target="_blank"
        rel="noopener noreferrer"
      >
        Twitter
      </a>
      {/* 他の連絡手段もここに追加可能 */}
    </div>
  );
}

export default Contact;
