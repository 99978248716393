import React from 'react';
import { css } from '@emotion/css';
import kokotro_image from "../../images/profile.jpg"; // 画像のパス指定

// 自己紹介セクションのスタイル
const aboutmeStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: #f8f8f8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  margin: 30px 0;
`;

const sectionStyle = css`
  text-align: center;
  margin-bottom: 15px; // セクション間の余白を減らす
`;

const imageStyle = css`
  max-width: 150px;
  margin-bottom: 20px;
  border-radius: 50%;
  border: 3px solid #eee;
`;

function Aboutme() {
  return (
    <div className={aboutmeStyle}>
      <img src={kokotro_image} alt="about" className={imageStyle} />
      <div className={sectionStyle}>
        <h3>
          このサイトについて
        </h3>
        <p>
          MIX依頼者用のページだよ
        </p>
      </div>
      <div className={sectionStyle}>
        <h3>
          はじめに
        </h3>
        <p>
          今はiphoneで録音できる良い時代なので、<br/>できる限りお金をかけずに活動していきたいですよね<br/><br/>
        </p>
      </div>
      <div className={sectionStyle}>
        <h3>
          使用プラグイン
        </h3>
        <p>
          iZotope製品(Ozone11 Advanced, Nectar4 Advanced, Neutron4, etc...)<br/>
          WAVES Platinum<br/>
        </p>
      </div>
      {/* さらに詳細な情報を追加 */}
    </div>
  );
}

export default Aboutme;
